import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppVersionService } from './app-version-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() currentYear;
  appVersion: string;

  private unsubscribe$ = new Subject();


  constructor(private appVersionService: AppVersionService) { }


  ngOnInit() {
    this.currentYear = new Date().getFullYear();

    this.appVersionService.getVersion()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(version => {
        this.appVersion = version;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
