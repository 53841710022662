import { Directive, HostListener, ElementRef, Renderer2 } from "@angular/core";

@Directive({
  selector: '[appDropdown]'
})

export class DropdownDirective {
  manageDropdown : boolean = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2)   {

  }

  @HostListener('document:click', ['$event']) toggleDropdown() {
    if (this.elementRef.nativeElement.contains(event.target)) { // or some similar check
      if(!this.manageDropdown) {
        this.renderer.addClass(this.elementRef.nativeElement.lastElementChild,'show');
        this.manageDropdown = !this.manageDropdown;
      } else {
        this.renderer.removeClass(this.elementRef.nativeElement.lastElementChild, 'show');
        this.manageDropdown = !this.manageDropdown;
      }
    } else {
      if(this.manageDropdown) {
        this.renderer.removeClass(this.elementRef.nativeElement.lastElementChild, 'show');
        this.manageDropdown = !this.manageDropdown;
      }
    }
  }
}