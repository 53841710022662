import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { ErrorPagesModule } from './error-pages/error-pages.module';

import { LocalStorage } from '@ngx-pwa/local-storage';
import { Langs, DEFAULT_LANG } from './core/nav-menu/nav-menu.component';
import { take } from 'rxjs/operators';
import { PlanetsModule } from './planets/planets.module';
import { LogoutComponent } from './core/auth/logout-component';
import { ReportsModule } from './reports/reports.module';

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    PlanetsModule,
    TranslateModule.forRoot({
      loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    ReportsModule,
    ErrorPagesModule // should be the last module because consists the routing wildcard
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, LocalStorage],
      multi: true
    }
  ],
  exports: [
    TranslateModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translateService: TranslateService,
  localStorage: LocalStorage): () => Promise<{}> {
  return () => new Promise(resolve => {

    localStorage.getItem<string>('lang')
    .pipe(take(1))
    .subscribe((lang: string) => {
      let langToSet: string;
      if (lang && (lang in Langs)) {
        langToSet = lang;
      } else {
        langToSet = DEFAULT_LANG;
      }
      translateService.setDefaultLang(DEFAULT_LANG);
      translateService.use(langToSet);
      resolve(null);
    });

  });
}
