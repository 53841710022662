import { Directive, HostListener, ElementRef, Renderer2 } from "@angular/core";

@Directive({
  selector: '[appNavbarCollapse]'
})

export class NavbarCollapseDirective {
  manageNavbarMenu : boolean = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2)   {

  }

  @HostListener('click', ['$event.target']) openNavbar(target: HTMLElement) {
      if(target.className === 'navbar-toggler' || target.className === 'navbar-toggler-icon') {
        const children = this.elementRef.nativeElement.children;
        const navbarCollapse = Array.from(children).find((element: HTMLElement) => {
            return element.className.indexOf('navbar-collapse') > -1;
        });
    
        if(!this.manageNavbarMenu) {
            this.renderer.addClass(navbarCollapse,'show');
            this.manageNavbarMenu = !this.manageNavbarMenu;
        } else {
            this.renderer.removeClass(navbarCollapse, 'show');
            this.manageNavbarMenu = !this.manageNavbarMenu;
        }
      }
  }
}