import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Planet, Survey, SurveyAndPlanet } from '../planets.models';

@Component({
  selector: 'assign-planet',
  templateUrl: './assign-planet.component.html',
  styleUrls: ['./assign-planet.component.scss']
})
export class AssignPlanetComponent implements OnInit {

  @Input() planets: Planet[];
  @Input() survey: Survey;
  @Output() addToPlanet = new EventEmitter<SurveyAndPlanet>();

  checkboxButtonDisabled: boolean = true;
  selectButtonDisabled: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  onCheckboxChange(e, survey: Survey) {
    this.checkboxButtonDisabled = !survey.missionTypeSelectList.some(item => item.checked);
  }
  onSelectChange(e) {
    if (e.target.value !== '') {
      this.selectButtonDisabled = false;
    }
  }
  
  passData(survey: Survey, planetId: string) {
    this.addToPlanet.emit({survey, planetId});
  }

}
