<div class="modal-content">
    <div class="modal-header">
        <h4>{{(title || 'confirm.defaultTitle') | translate}}</h4>
    </div>
    <div class="modal-body">
        <p>{{(message || 'confirm.defaultMsg') | translate}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()" >{{'buttons.cancel' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="confirm()">{{'buttons.yes' | translate}}</button>
    </div>
</div>