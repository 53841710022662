import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from './core/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private unsubscribe = new Subject();
  userAuthenticated: boolean = false;

  constructor(private authService: AuthService) {
  
    this.authService.getUser()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user) => {
        if (user.authenticated && window.location.pathname.toLowerCase() !== "/logout") {
          this.userAuthenticated = true;
        }
      });
  }
}
