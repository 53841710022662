import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanetsComponent } from './planets.component';
import { ManagePlanetComponent } from './manage-planet/manage-planet.component';
import { PlanetsRoutingModule } from './planets-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { TagInputModule } from 'ngx-chips';
import { AssignPlanetComponent } from './assign-planet/assign-planet.component';

@NgModule({
  declarations: [PlanetsComponent, ManagePlanetComponent, AssignPlanetComponent],
  imports: [
    CommonModule,
    PlanetsRoutingModule,
    SharedModule,
    TagInputModule
  ]
})
export class PlanetsModule { }
