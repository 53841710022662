import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ReportsComponent } from './reports.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';


@NgModule({
  declarations: [
    ReportsComponent
  ],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    TranslateModule,
    HttpClientModule
  ],
  providers: [DatePipe]
})
export class ReportsModule { }
