import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private generateReportUrl = '/api/Export/GenerateReport';
  private getMonthsUrl = '/api/Export/GetMaxMonthsSpan';

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  getMonths(): Observable<number> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(this.getMonthsUrl, { headers, responseType: 'text' })
      .pipe(map(response => {
        return Number(response);
      }));
  }

  generateReport(dateFrom: Date, dateTo: Date): Promise<Response> {
    return fetch(this.generateReportUrl + "?startDate=" + this.datePipe.transform(dateFrom, 'MM/dd/yyyy') + "&endDate=" + this.datePipe.transform(dateTo, 'MM/dd/yyyy'), {
      method: 'GET'
    })
  }
}
