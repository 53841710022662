import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, enGbLocale } from 'ngx-bootstrap/chronos';
import { ReportsService } from './reports.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

  maxDateFrom: Date;
  minDateTo: Date;
  maxDateTo: Date;
  dateFrom: Date;
  dateTo: Date;
  tmpFileName: string;
  months: number;
  private subscription: Subscription;
  
  constructor(
    private localeService: BsLocaleService,
    private reportsService: ReportsService
  ) {
    this.maxDateFrom = new Date();
    this.minDateTo = new Date();
    this.maxDateTo = new Date();
  }

  onValueChange(value: Date): void {
    if (value) {
      this.dateFrom = value;
      this.minDateTo = new Date(this.dateFrom);
      this.minDateTo.setDate(this.dateFrom.getDate());
      this.maxDateTo = new Date(this.dateFrom);
      this.maxDateTo.setMonth(this.dateFrom.getMonth() + this.months);
    }
    if (this.dateTo) {
      this.dateTo = null;
    }
  }
  
  getMonths() {
    this.subscription = this.reportsService.getMonths().subscribe(
      months => {
        this.months = months;
      }
    )
  }

  ngOnInit(): void {
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');

    this.getMonths();    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  export() {
    this.reportsService.generateReport(this.dateFrom, this.dateTo)
      .then(response => {
        this.tmpFileName = response.headers.get('content-disposition');
        return response.blob();
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', this.tmpFileName.split(';')[1].split('=')[1]);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
}
