import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { User } from '../auth/auth.models';
import { AuthService } from '../auth/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// tslint:disable:comment-format

export enum Langs {
  en = 'EN',
  de = 'DE'
}

export const DEFAULT_LANG = 'en';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})

export class NavMenuComponent implements OnInit, OnDestroy {
  isExpanded = false;
  langs = Langs;
  keys = Object.keys;
  currentLang: string;
  user: User = {
    authenticated: false,
    id: null,
    roles: null,
    claims: null,
    isAdmin: false,
    displayName: null,
  };
  profileUrl: string;

  private unsubscribe = new Subject();

  constructor(private translate: TranslateService,
    private localStorage: LocalStorage,
    private authService: AuthService) { }

  ngOnInit() {
    this.localStorage.getItem<string>('lang')
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((lang: string) => {
      if (lang) {
        this.currentLang = lang;
      } else {
        this.currentLang = DEFAULT_LANG;
      }
    });

    this.authService.getUser()
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      res => {
        this.user = res;
      });

    this.authService.getIdmTenantUrl().subscribe(IDMurl => {
      this.profileUrl = IDMurl;
    });
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  signout() {
    this.authService.signout();
  }

  onLanguageChange($event) {
    const lang = $event.currentTarget.value;
    this.localStorage.setItem('lang', lang)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(() => {});
    this.translate.use(lang);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
