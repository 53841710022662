import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlanetsComponent } from './planets/planets.component';
import { PlanetsGuard } from './planets/planets.guard';
import { LogoutComponent } from './core/auth/logout-component';
import { ReportsComponent } from './reports/reports.component';

const appRoutes: Routes = [
  { path: '', component: PlanetsComponent, pathMatch: 'full', canActivate: [PlanetsGuard] },
  { path: 'reports', component: ReportsComponent, pathMatch: 'full', canActivate: [PlanetsGuard]},
  { path: 'logout', component: LogoutComponent }];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, relativeLinkResolution: 'legacy' } // <-- debugging purposes only
 // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
