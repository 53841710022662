<div class="row">
    <div class="col-12">
        <h3><img src="../../assets/img/reports-icon.png"> {{'reports.title' | translate}}</h3>
        <p>{{'reports.description.line1' | translate}}<br />
        {{'reports.description.line2' | translate : { months: this.months } }}</p>
    </div>
</div>
<div class="row mt-4">
    <div class="col-12 form-group">
        <form #exportForm="ngForm">
            <div class="form-row">
                <div class="col-xl-2 col-auto mb-3">
                    <label for="from">{{'reports.from' | translate}}</label>
                    <input type="text"
                        id="from"
                        class="form-control"
                        name="dateFrom"
                        required
                        placement="bottom left"
                        onkeydown="return false"
                        autocomplete="off"
                        [(ngModel)]="dateFrom"
                        [bsConfig]="{maxDate: maxDateFrom, showWeekNumbers: false, dateInputFormat: 'DD/MM/YYYY' }"
                        bsDatepicker
                        (bsValueChange)="onValueChange($event)">
                </div>
                <div class="col-xl-2 col-auto mb-3">
                    <label for="to">{{'reports.to' | translate}}</label>
                    <input type="text"
                    id="to"
                    class="form-control"
                    name="dateTo"
                    required
                    placement="bottom left"
                    onkeydown="return false"
                    autocomplete="off"
                    [(ngModel)]="dateTo"
                    bsDatepicker
                    [disabled]="!dateFrom"
                    [bsConfig]="{minDate: minDateTo, maxDate: maxDateTo, showWeekNumbers: false, dateInputFormat: 'DD/MM/YYYY'}">
                </div>
                <div class="col-xl-2 col-auto mb-3 d-flex align-items-end">
                    <button type="button" class="btn btn-primary " [disabled]="!exportForm.form.valid" (click)="export()"><img class="mb-1 mr-2" src="../../assets/img/download-icon.svg"> {{'reports.export' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>
