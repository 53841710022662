import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PlanetsService } from '../planets.service';
import { Planet } from '../planets.models';
import { SimpleModalService } from "ngx-simple-modal";
import { ConfirmComponent } from '@app/shared/components/confirm/confirm.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { TagInputComponent } from 'ngx-chips';

@Component({
  selector: 'app-manage-planet',
  templateUrl: './manage-planet.component.html',
  styleUrls: ['./manage-planet.component.scss']
})
export class ManagePlanetComponent implements OnInit {

  @ViewChild(TagInputComponent)
  tagInput: TagInputComponent;

  isAdd = false;
  groups: string[];
  name: string;
  private planetId: string;
  modal: any;

  constructor(private router: Router,
    private planetsService: PlanetsService,
    private route: ActivatedRoute,
    private simpleModalService: SimpleModalService,
    private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit() {
    if (this.router.url.includes('/add')) {
      this.isAdd = true;
    } else {
      // init edit planet info
      this.planetId = this.route.snapshot.paramMap.get('id');
      this.planetsService.getPlanetById(this.planetId).subscribe(
        (planet: Planet) => {
          this.name = planet.name;
          this.groups = planet.userGroupIds;
        }
      );
    }
  }

  backToList() {
    this.router.navigate(['/']);
  }

  private showError() {
    this.translate.get('errorMessages.operationNotSuccessful').pipe(take(1)).subscribe((msg: string) => {
      this.toastr.error(this.translate.instant(msg), null, {
        disableTimeOut: true
      });
    });
  }
  private showResponseError(response: any) {
    const error = response.error.error;
    if (!error) {
      this.showError();
    } else {
      const errorMessageKey = 'errorMessages.' + error;
      this.translate.get(errorMessageKey).pipe(take(1)).subscribe((msg: string) => {
        this.toastr.error(this.translate.instant(msg), null, {
          disableTimeOut: true
        });
      });
    }
  }
  addPlanet() {
    //this.addUnsavedTags();

    const planet = {
      name: this.name,
      userGroupIds: this.groups,
      surveys: []
    };
    this.planetsService.addPlanet(planet).subscribe(
      () => {
        this.backToList();
      },
      (response) => {
        this.showResponseError(response);
      }
    );
  }

  private deletePlanet() {
    this.planetsService.deletePlanet(this.planetId).subscribe(
      () => {
        this.backToList();
      },
      () => {
        this.showError();
      }
    );
  }

  private addUnsavedTags() {
    // ensure autoadding in case of unsaved tags
    if (!!this.tagInput.inputTextValue) {
      this.groups.push(this.tagInput.inputTextValue);
    }
  }

  cancelChange() {
    this.backToList();
  }

  editPlanet() {
    //this.addUnsavedTags();

    const updatedPlanet = {
      id: this.planetId,
      name: this.name,
      userGroupIds: this.groups
    }
    this.planetsService.editPlanet(updatedPlanet).subscribe(
      () => {
        this.backToList();
      },
      (response) => {
        this.showResponseError(response);
      }
    );
  }

  showConfirmDelete() {
    this.modal = this.simpleModalService.addModal(ConfirmComponent, {
      title: 'deletePlanet.title',
      message: 'deletePlanet.message'
    })
      .subscribe((isConfirmed) => {
        //We get modal result
        if (isConfirmed) {
          this.deletePlanet();
        }
        else {
          this.modal.unsubscribe();
        }
      });
  }

  ngOnDestroy() {
    if (!!this.modal) {
      this.modal.unsubscribe();
    }
  }

}
