<div class="row mb-2">
    <div class="col-9">
        <div class="btn btn-secondary float-right" *ngIf="!isAdd" (click)="showConfirmDelete()">
            <span class="icon icon-trash d-inline-block mr-2"></span>
            {{'managePlanet.delete' | translate}}
        </div>
    </div>
</div>
<form class="form" #planetForm="ngForm">
    <div class="form-group row">
        <div class="col-3">
            <div class="label">
                {{'managePlanet.name' | translate}}
            </div>
        </div>
        <div class="col-6">
            <input class="form-control" [(ngModel)]="name" name="name"
                [ngClass]="{'is-invalid': planetForm.controls.name && planetForm.controls.name.touched && planetForm.controls.name.invalid}"
                required>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-3">
            <div class="label">
                {{'managePlanet.groups' | translate}}
            </div>
        </div>
        <div class="col-6">
            <tag-input [(ngModel)]="groups"
                class="mb-3"
                name="groups"
                [addOnBlur]="true"
                [addOnPaste]="true"
                [modelAsStrings]="true"
                placeholder="{{'managePlanet.groupsPlaceholder' | translate}}"
                secondaryPlaceholder="{{'managePlanet.groupsPlaceholder' | translate}}"></tag-input>
            <i>{{'managePlanet.noSelectedGroupsMsg' | translate}}</i>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-9">
            <button *ngIf="!isAdd" class="btn btn-primary float-right" [disabled]="!planetForm.valid" (click)="editPlanet()">
                {{'buttons.save' | translate}}
            </button>

            <button *ngIf="isAdd" class="btn btn-primary float-right" [disabled]="!planetForm.valid" (click)="addPlanet()">
                {{'buttons.add' | translate}}
            </button>

            <button class="btn btn-secondary float-right mr-3" (click)="cancelChange()">
                {{'buttons.cancel' | translate}}
            </button>
        </div>
    </div>
    
</form>
