<app-loader></app-loader>
<div class="container-fluid d-flex flex-column h-100">

  <app-nav-menu *ngIf="userAuthenticated"></app-nav-menu>

  <div class="flex-grow-1 flex-shrink-0">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer *ngIf="userAuthenticated"></app-footer>
</div>
