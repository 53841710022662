<nav class="navbar navbar-expand-lg navbar-dark bg-dark row mb-5">
    <span alt="KPMG" class="navbar-brand m-1 logo-svg logo-svg-white" style="font-size: 6em;"></span>
    <h1 class="navbar-brand">{{'home.title' | translate}}</h1>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02"
        aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarColor02">
        <ul class="navbar-nav d-flex align-items-lg-center ml-lg-4">
            <li [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: true }' class="nav-item">
                <a [routerLink]='["/"]' (click)='collapse()' class="nav-link">{{'navigation.home' | translate}}</a>
            </li>
            <li [routerLinkActive]='["active"]'  [routerLinkActiveOptions]='{ exact: true }' class="nav-item" *ngIf="user.authenticated">
                <a [routerLink]='["reports"]' (click)='collapse()' class="nav-link">{{'navigation.reports' | translate}}</a>
            </li>
        </ul>
        <ul class="navbar-nav d-flex align-items-lg-center ml-auto">
            <li [routerLinkActive]='["active"]' class="nav-item dropdown" appDropdown>
                <a [routerLink]="" [ngClass]="{ 'dropdown-toggle':user.authenticated }" class="nav-link user-info flex-auto ml-auto d-flex align-items-center text-white" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div *ngIf="user.authenticated" class="d-flex flex-2 flex-column mx-3 pull-right user-text">
                        {{'user.hello' | translate}}, <br /> {{user.displayName}}
                    </div>
                    <div *ngIf="!user.authenticated" class="d-flex flex-2 flex-column mx-3 pull-right user-text">
                        {{'user.naLine1' | translate}}<br /> {{'user.naLine2' | translate}}
                    </div>
                </a>
                <div *ngIf="!user.authenticated" class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="/account/login">{{'user.login' | translate}}</a>
                </div>
                <div *ngIf="user.authenticated" class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" [href]="profileUrl" target="_blank" (click)='collapse()'>{{'user.details' | translate}}</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/account/logout" target="_self" (click)='signout()'>{{'user.logout' | translate}}</a>
                </div>
            </li>
            <li class="nav-item">
                <div class="form-group mb-0">
                    <select class="form-control form-control-sm select-lang" (change)="onLanguageChange($event)"
                        [ngModel]="currentLang">
                        <option value="en" *ngFor="let lang of keys(langs)" [value]="lang">{{langs[lang]}}</option>
                    </select>
                </div>
            </li>
        </ul>
    </div>
</nav>
