import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit, OnDestroy {

  private unsubscribe = new Subject();

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.signout()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.router.navigate(['/']);
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
