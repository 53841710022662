import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Planet, Survey } from './planets.models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanetsService {

  private PLANET_URL = 'api/planet';
  private UNASSIGNED_URL = 'api/NotAssignedSurveys';

  constructor(private http: HttpClient) { }

  getPlanets(): Observable<Planet[]> {
    return this.http.get<Planet[]>(this.PLANET_URL);
  }

  getPlanetById(planetId: string): Observable<Planet> {
    return this.http.get<Planet>(this.PLANET_URL + '/' + planetId);
  }

  editPlanet(planet: Planet): Observable<Planet> {
    return this.http.put<Planet>(this.PLANET_URL + '/' + planet.id, planet);
  }

  addPlanet(planet: Planet) {
    return this.http.post<Planet>(this.PLANET_URL, planet);
  }

  deletePlanet(planetId: string) {
    return this.http.delete<Planet>(this.PLANET_URL + '/' + planetId);
  }

  getUnassignedSurveys(): Observable<Survey[]> {
    return this.http.get<Survey[]>(this.UNASSIGNED_URL);
  }
}
