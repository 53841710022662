import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ManagePlanetComponent } from './manage-planet/manage-planet.component';
import { PlanetsGuard } from './planets.guard';

const planetsRoutes: Routes = [
    {
        path: 'planet',
        children: [
            {
                path: 'edit/:id',
                component: ManagePlanetComponent
            },
            {
                path: 'add',
                component: ManagePlanetComponent
            }
        ],
        canActivate: [PlanetsGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(
            planetsRoutes
        )
    ],
    exports: [
        RouterModule
    ]
})

export class PlanetsRoutingModule {}