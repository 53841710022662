import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class RedirectInterceptor implements HttpInterceptor {
  constructor(private router: Router, private location: Location) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        const response = err as HttpErrorResponse;

        if (response.status === 401 || response.status === 403) {
          localStorage.removeItem('access_token');
          this.location.go('/account/login?returnUrl=' + this.router.url);
          window.location.reload();
        }
      }
    }));
  }
}
