import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnInit {
  @HostBinding('class') pageClass = 'error-page';

  constructor() { }

  ngOnInit() {
  }

}
