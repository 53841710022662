import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlanetsService } from './planets.service';
import { Planet, Survey, SurveyAndPlanet } from './planets.models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-planets',
  templateUrl: './planets.component.html',
  styleUrls: ['./planets.component.scss']
})
export class PlanetsComponent implements OnInit, OnDestroy {

  planets: Planet[];
  unassignedSurveys: Survey[];
  private unsubscribe$ = new Subject();

  constructor(private planetsService: PlanetsService) { }

  ngOnInit() {
    this.initPlanetsAndSurveys();
  }

  initPlanetsAndSurveys() {
    this.planetsService.getPlanets().pipe(takeUntil(this.unsubscribe$))
      .subscribe((planets: Planet[]) => {
        this.planets = planets;
      });

    this.planetsService.getUnassignedSurveys().pipe(takeUntil(this.unsubscribe$))
      .subscribe((surveys: Survey[]) => {
        this.unassignedSurveys = surveys;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addToPlanet(surveyAndPlanet: SurveyAndPlanet) {
    const survey = surveyAndPlanet.survey;
    const planetId = surveyAndPlanet.planetId;

    if(!planetId) { return }

    const currentPlanet = this.planets.find(planet => {
      return planet.id === planetId;
    });

    currentPlanet.surveys.push(survey);

    this.editPlanet(currentPlanet);
  }

  unassignSurveyFromPlanet(survey: Survey, planet: Planet) {
    planet.surveys = planet.surveys.filter(el => {
      return el.id !== survey.id;
    });

    this.editPlanet(planet);
  }

  private editPlanet(planet: Planet) {
    this.planetsService.editPlanet(planet).pipe(take(1))
    .subscribe(() => {
      this.initPlanetsAndSurveys();
    });
  }

}
