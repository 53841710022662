<div class="clearfix">
    <button class="btn btn-primary float-right mb-2" [routerLink]="['planet/add']">
        {{'planetsList.addPlanet' | translate}}
    </button>
</div>

<div class="row">
    <div class="col-12 col-md-6 col-lg-4 mb-3" *ngFor="let planet of planets">
        <div class="card planet-card">
            <div class="planet">
                <div class="planet-img"></div>
                <div class="planet-name">
                    <div class="btn btn-primary planet-btn-xs btn-next">{{planet.name}}</div>
                </div>
            </div>

            <div class="card-body">
                <ul class="list-unstyled">
                  <li *ngFor="let survey of planet.surveys" class="d-flex">
                    <div class="badge-group d-flex mr-2">
                        <span class="badge badge-pill badge-primary" *ngFor="let abbr of survey.missionTypeAbbrs">{{abbr}}</span>
                    </div>
                    <span class="flex-grow-1">{{survey.title}}</span>
                    <div class="action-buttons d-flex align-items-center">
                        <button class="btn btn-link mt-1" (click)="unassignSurveyFromPlanet(survey, planet)">
                            <span class="icon icon-times"></span>
                        </button>
                        
                    </div>
                </li>
                </ul>
                <a [routerLink]="['planet/edit', planet.id]" class="btn btn-primary w-100">{{'buttons.edit' | translate}}</a>
            </div>
        </div>
    </div>

</div>
    
<h5 *ngIf="unassignedSurveys && unassignedSurveys.length > 0" class="mt-5">{{'planetsList.unassigned' | translate}}</h5>
<assign-planet  *ngFor="let survey of unassignedSurveys" [survey]="survey" [planets]="planets" (addToPlanet)="addToPlanet($event)"></assign-planet>