import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

const XAPP_ADMIN_ROLE = 'Experience App Admin';

@Injectable({
  providedIn: 'root',
})
export class PlanetsGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {

    let isXAppAdmin: boolean;

    return this.authService.getUser().pipe(
      map(user => {
        if(!user.authenticated) {
          window.location.replace('/account/login');
          return false;
        }
        
        isXAppAdmin = user.roles && user.roles.indexOf(XAPP_ADMIN_ROLE) > -1;

        if (!isXAppAdmin) {
          this.router.navigate(['403']);
          return false;
        }

        return isXAppAdmin;
      }),
      catchError(error => {
        this.router.navigate(['401']);
        return of(false);
      })
    );
  }
}
