import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorComponent } from './components/error/error.component';
import { InputMaskDirective } from './directives/input-mask/input-mask.directive';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { DropdownDirective } from './directives/dropdown/dropdown.directive';
import { NavbarCollapseDirective } from './directives/navbar-collapse/navbar-collapse.directive';

const NOTIFICATION_TIMEOUT = 20000;

@NgModule({
  declarations: [
    ErrorComponent,
    InputMaskDirective,
    ConfirmComponent,
    ConfirmComponent,
    DropdownDirective,
    NavbarCollapseDirective
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({'timeOut': NOTIFICATION_TIMEOUT}),
    SimpleModalModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorComponent,
    InputMaskDirective,
    ToastrModule,
    SimpleModalModule,
    DropdownDirective,
    NavbarCollapseDirective
  ],
  entryComponents: [
    ConfirmComponent
  ]
})
export class SharedModule { }
