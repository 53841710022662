<div class="row mt-2 mb-4" >
    <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
        <div class="survey">
            <div class="survey-image"></div>
            <h5>{{survey.title}}</h5>
        </div>
    </div>

    <div class="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 mt-3 mt-sm-0">
        <label>{{'planetsList.selectPlanet' | translate}}</label>
        <select class="form-control d-flex mb-4" #select (change)="onSelectChange($event)">
            <option></option>
            <option *ngFor="let planet of planets" [value]="planet.id">{{planet.name}}</option>
        </select>
        <div class="custom-control custom-checkbox mb-3" *ngFor="let mType of survey.missionTypeSelectList">
            <input type="checkbox" class="custom-control-input" [id]="mType.value + '-' + survey.id" [value]="mType.value" [(ngModel)]="mType.checked" (change)="onCheckboxChange($event, survey)">
            <label class="custom-control-label" [for]="mType.value + '-' + survey.id">{{'planetsList.' + mType.text | translate}}</label>
        </div>
        <button class="btn btn-primary mt-2 float-right d-flex"  [disabled]="(checkboxButtonDisabled || selectButtonDisabled)" (click)="passData(survey, select.value)">{{'planetsList.addToPlanet' | translate}}</button>
    </div>
</div>